.HF-light-border {
  border-color: #cecece;
}

.nav-pills .HF-setup.nav-link:not(.active) {
  background-color: (var(--bs-light-rgb));
}
.navbar-nav.nav-pills .HF-setup.nav-link.active  {
  background-color: #999999;
  color: #eeeeee;
}

.App {
  /* text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.Capital {
  text-transform: capitalize;
}
.bookBtn:disabled {
  background-color: #999999 !important;
  text-decoration: line-through;
}
.bookBtnCtn {
  position: relative;
}
.whoBook {
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: 5px;
}


